import Vue from "vue";
import { ISelectedFilterValue } from "../../components/v2/filters/models/selected-filter-value";

export const filtersModule = {
  namespaced: true,
  state: {
    showFilter: false,
    selectedFields: [],
    availableOptions: [],
    selectedFilters: [],
    refreshTrigger: 0
  },
  getters: {
    getShowFilter: (state) => state.showFilter,
    getSelectedFields: (state) => state.selectedFields,
    getAvailableOptions: (state) => state.availableOptions,
    getSelectedFilters: (state) => (hardwareType?) => {
      if(hardwareType) return state.selectedFilters.filter((f) => f.hardwareType === hardwareType);
      return state.selectedFilters
    },
    getRefreshTrigger: (state) => state.refreshTrigger,
    getValuesForField: state => (hardwareType, field) => {
      const filter = state.selectedFilters.find((f) => f.hardwareType === hardwareType && f.field === field);
      return filter ? filter.value : [];
    },
    getFilterCount: state => (hardwareType) => {
      return state.selectedFilters.filter((f) => f.hardwareType === hardwareType)?.filter(f => f.value?.length)?.length;
    }
  },
  actions: {},
  mutations: {
    setShowFilter(state, value) {
      state.showFilter = value;
    },
    setSelectedFields(state, value) {
      state.selectedFields = value;
    },
    setAvailableOptions(state, value) {
      state.availableOptions = value;
    },
    setSelectedFilters(state, value) {
      state.selectedFilters = value;
    },
    addSelectedFilter(state, filter: ISelectedFilterValue) {
      const foundIndex = state.selectedFilters.findIndex((f) => f.hardwareType === filter.hardwareType && f.field === filter.field);
      if (foundIndex === -1) {
        state.selectedFilters.push(filter);
      } else if (!filter.value?.length) {
        state.selectedFilters.splice(foundIndex, 1);
      } else {
        Vue.set(state.selectedFilters, foundIndex, filter); // to trigger deeper reactivity
      }
    },
    resetSelectedFiltersFromHardwareType(state, hardwareType) {
      state.selectedFilters = state.selectedFilters.filter((f) => f.hardwareType !== hardwareType);
    },
    incrementRefreshTrigger(state) {
      state.refreshTrigger++;
    }

  }
};
